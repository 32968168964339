import moment from 'moment';
import { Offset } from '@/models/Offset';

export default class SalesHistoricFilters {
  searchType = 'customerTaxDocument'
  methods = [
    'bankslip',
    'bankslipInstallments',
    'credit',
    'debit',
  ]
  status = {
    paid: true,
    canceled: true,
    pending: true,
    overdue: true,
    unauthorized: true,
    disputed: true,
  }
  cardBrands = null
  sellingChannels = null
  bracket = null
  nsu = ''
  bankSlipId = ''
  paymentId = ''
  description = ''
  customerName = ''
  customerTaxDocument = ''
  sellingKey = null
  interval = {
    startDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  }
  offset = new Offset()
}
