<template>
  <form class="mx-lg-2">
    <div class="form-row mb-4">
      <div class="col-12 col-lg-auto d-flex">
        <span class="m-lg-auto h4 font-weight-light">Ver</span>
      </div>
      <div class="col-12 col-lg mb-3 mb-lg-0">
        <div class="dropdown">
          <div
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center
            "
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-nowrap">{{ aliasMethods }}</span>
          </div>
          <div
            class="dropdown-menu w-100"
            aria-labelledby="dropdownMenuButton"
            @click.stop
          >
            <WhiteLabel>
              <label
                class="form-check-label dropdown-item d-flex"
                for="bank_slip"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="bla"
                  v-model="filters.methods"
                  value="bankslip"
                  id="bank_slip"
                  :disabled="
                    filters.methods.includes('bankslip') &&
                    filters.methods.length === 1
                  "
                />
                <div class="checkbox-badge my-auto mr-3"></div>
                Boletos
              </label>
            </WhiteLabel>
            <WhiteLabel>
              <label
                class="form-check-label dropdown-item d-flex"
                for="installment"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="bla"
                  v-model="filters.methods"
                  value="bankslipInstallments"
                  id="installment"
                  :disabled="
                    filters.methods.includes('bankslipInstallments') &&
                    filters.methods.length === 1
                  "
                />
                <div class="checkbox-badge my-auto mr-3"></div>
                Parcelas de carnê
              </label>
            </WhiteLabel>
            <WhiteLabel>
              <label
                class="form-check-label dropdown-item d-flex"
                for="credit_card"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="bla"
                  v-model="filters.methods"
                  value="credit"
                  id="credit_card"
                  :disabled="
                    filters.methods.includes('credit') &&
                    filters.methods.length === 1
                  "
                />
                <div class="checkbox-badge my-auto mr-3"></div>
                Transações no crédito
              </label>
            </WhiteLabel>
            <WhiteLabel>
              <label
                class="form-check-label dropdown-item d-flex"
                for="debit_card"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="bla"
                  v-model="filters.methods"
                  value="debit"
                  id="debit_card"
                  :disabled="
                    filters.methods.includes('debit') &&
                    filters.methods.length === 1
                  "
                />
                <div class="checkbox-badge my-auto mr-3"></div>
                Transações no débito
              </label>
            </WhiteLabel>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mb-3 mb-lg-0">
        <select
          class="form-control rounded-pill text-left text-lg-center"
          v-model="status"
        >
          <option value="created">gerado(s)</option>
          <option value="paid">pago(s)</option>
          <option value="pending">que vence(m)</option>
          <option value="overdue">vencido(s)</option>
          <option value="canceled">cancelado(s)</option>
          <option value="unauthorized">não autorizado(s)</option>
          <option value="miscelaneous">customizado(s)</option>
        </select>
      </div>
      <div class="col-12 col-lg mb-3 mb-lg-0">
        <div class="dropdown">
          <div
            class="
              form-control
              dropdown-select-like
              rounded-pill
              text-left text-lg-center text-nowrap
            "
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              v-if="selectedDate.start.getTime() !== selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">entre&nbsp;</span>
              <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
              <span class="text-xs text-ghost">&nbsp;e&nbsp;</span>
              <span>{{ selectedDate.end | moment("DD/MM/YY") }}</span>
            </div>
            <div
              v-if="selectedDate.start.getTime() === selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">no dia&nbsp;</span>
              <span>{{ selectedDate.start | moment("DD/MM/YY") }}</span>
            </div>
          </div>
          <div
            class="dropdown-menu dropdown-menu-fixed w-100"
            aria-labelledby="dropdownMenuButton"
            @click.stop
          >
            <v-date-picker
              mode="range"
              v-model="selectedDate"
              :show-day-popover="false"
              :tint-color="datePickerColor"
              show-caps
              is-inline
            ></v-date-picker>
          </div>
        </div>
      </div>
      <div class="col-auto d-none d-md-flex">
        <WhiteLabel>
          <a href="#" class="text-sm my-auto btn-more-filters"
            >+ {{ filtersOn ? "menos" : "mais" }} filtros</a
          >
        </WhiteLabel>
      </div>
    </div>
    <div class="form-row mb-4 more-filters">
      <div class="col-12 col-lg mb-3 mb-lg-0">
        <select
          class="form-control rounded-pill text-lg-center"
          v-model="filters.cardBrands"
        >
          <option value="null">Todas as bandeiras</option>
          <option value="amex">American Express</option>
          <option value="banesecard">Banese Card</option>
          <option value="dinersclub">Diners Club</option>
          <option value="elo">Elo</option>
          <option value="hipercard">HiperCard</option>
          <option value="mastercard">MasterCard</option>
          <option value="visa">Visa</option>
        </select>
      </div>
      <!-- <div class="col-12 col-lg mb-3 mb-lg-0">
        <select class="form-control rounded-pill text-lg-center">
          <option value="">Todos os usuários</option>
          <option>Washington</option>
        </select>
      </div>-->
      <div class="col-12 col-lg mb-3 mb-lg-0">
        <select
          class="form-control rounded-pill text-lg-center"
          v-model="filters.sellingChannels"
        >
          <option value="null">Todos os canais</option>
          <option value="pinpad">Pinpad</option>
          <option value="online">Online/digitada</option>
        </select>
      </div>
      <div class="col-12 col-lg mb-3 mb-lg-0">
        <select
          class="form-control rounded-pill text-lg-center"
          v-model="filters.bracket"
        >
          <option value="null">Todos os tipos</option>
          <option value="authorized">Pré-capturadas</option>
        </select>
      </div>
    </div>
    <div class="form-row justify-content-center mb-4">
      <div class="col-12 d-flex d-lg-none">
        <span class="h4 font-weight-light">Procurar por:</span>
      </div>
      <div class="col-12 col-lg-8 col-xl-7">
        <div class="input-group input-group-responsive">
          <div class="input-group-prepend col-auto px-0">
            <select
              class="form-control rounded-pill text-left text-lg-center"
              v-model="filters.searchType"
            >
              <option value="customerTaxDocument">CPF/CNPJ</option>
              <option value="nsu">NSU</option>
              <option value="bankslipId">Nosso número</option>
              <option value="paymentId">ID do pagamento</option>
              <option value="note">Descrição do pagamento</option>
              <!-- <option value="cardFinal">Final do Cartão</option> -->
              <option value="payerName">Nome do cliente</option>
              <option value="sellingKey">Chave de Transação</option>
            </select>
          </div>
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'customerTaxDocument'"
            placeholder="digite a informação"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="filters.customerTaxDocument"
          />
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'nsu'"
            placeholder="digite a informação"
            v-model="filters.nsu"
          />
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'bankslipId'"
            placeholder="digite a informação"
            v-model="filters.bankSlipId"
          />
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'paymentId'"
            placeholder="digite a informação"
            v-model="filters.paymentId"
          />
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'note'"
            placeholder="digite a informação"
            v-model="filters.description"
          />
          <!-- <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'cardFinal'"
            placeholder="digite a informação"
          >-->
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'payerName'"
            placeholder="digite a informação"
            v-model="filters.customerName"
          />
          <input
            type="text"
            class="form-control rounded-pill"
            v-show="filters.searchType === 'sellingKey'"
            placeholder="digite a chave de transação"
            v-model="filters.sellingKey"
          />
        </div>
        <!-- <small class="text-danger position-absolute ml-3 mt-1">CPF inválido</small> -->
      </div>
    </div>
    <div class="d-flex d-md-none mt-5">
      <button class="btn btn-orange btn-pill mb-4 mx-auto">
        Aplicar filtros
      </button>
    </div>
  </form>
</template>

<script>
import { setupCalendar, Calendar } from 'v-calendar';
import { cpfCnpj } from '@/validations/cpfCnpj';
import { onlyNumbers } from '@/validations/onlyNumbers';
import { onlyLettersNumbers } from '@/validations/onlyLettersNumbers';
import SalesHistoricFilters from '@/models/SalesHistoricFilters';
import PaymentsApi from '@/services/v3/PaymentsApi';
import 'v-calendar/lib/v-calendar.min.css';
import { Offset } from '@/models/Offset';
import WhiteLabel from '@/components/shared/WhiteLabel';

import moment from 'moment';
export default {
  name: 'AppSalesForm',
  components: {
    WhiteLabel,
  },
  data() {
    return {
      selectedDate: {
        start: new Date().addDays(-7),
        end: new Date(),
      },
      filters: new SalesHistoricFilters(),
      status: 'created',
      historic: [],
      filtersOn: false,
    };
  },
  async mounted() {
    $('.btn-more-filters').on('click', async() => this.show());

    if (localStorage.hasOwnProperty('historicFilters')) {
      this.filters = await JSON.parse(localStorage.getItem('historicFilters'));
      this.selectedDate.start = moment(
        this.filters.interval.startDate
      ).toDate();
      this.selectedDate.end = moment(this.filters.interval.endDate).toDate();
      this.filters.offset.index = 0;
    } else {
      await this.getHistoric(false);
    }
  },
  methods: {
    show() {
      $('.more-filters').toggleClass('show');
      this.filtersOn = !this.filtersOn;
    },

    async getHistoric(paginate) {
      const api = new PaymentsApi();

      this.$bus.$emit('spinner-run');

      if (!paginate) {
        this.filters.offset.index = 0;
      }
      this.historic = await api.getTransactions(this.filters);
      this.$emit('captureHistoric', {
        historic: this.historic,
        paginate: paginate,
      });

      localStorage.setItem('historicFilters', JSON.stringify(this.filters));
      this.$bus.$emit('spinner-stop');
    },
    async setStatus(totalizers) {
      if (totalizers) {
        if (
          this.filters.status.paid &&
          !this.filters.status.pending &&
          !this.filters.status.overdue &&
          !this.filters.status.canceled &&
          !this.filters.status.unauthorized
        ) {
          this.status = 'paid';
        } else if (
          !this.filters.status.paid &&
          !this.filters.status.pending &&
          this.filters.status.overdue &&
          !this.filters.status.canceled &&
          !this.filters.status.unauthorized
        ) {
          this.status = 'overdue';
        } else if (
          !this.filters.status.paid &&
          !this.filters.status.pending &&
          !this.filters.status.overdue &&
          this.filters.status.canceled &&
          !this.filters.status.unauthorized
        ) {
          this.status = 'canceled';
        } else if (
          !this.filters.status.paid &&
          this.filters.status.pending &&
          !this.filters.status.overdue &&
          !this.filters.status.canceled &&
          !this.filters.status.unauthorized
        ) {
          this.status = 'pending';
        } else if (
          !this.filters.status.paid &&
          !this.filters.status.pending &&
          !this.filters.status.overdue &&
          !this.filters.status.canceled &&
          this.filters.status.unauthorized
        ) {
          this.status = 'unauthorized';
        } else if (
          this.filters.status.paid &&
          this.filters.status.pending &&
          this.filters.status.overdue &&
          this.filters.status.canceled &&
          this.filters.status.unauthorized
        ) {
          this.status = 'created';
        } else {
          this.status = 'miscelaneous';
        }

        if (
          !this.filters.status.paid &&
          !this.filters.status.pending &&
          !this.filters.status.overdue &&
          !this.filters.status.canceled &&
          !this.filters.status.unauthorized
        ) {
          this.$bus.$emit('spinner-run');
          this.historic = { transactions: [] };
          this.$emit('captureHistoric', {
            historic: this.historic,
            paginate: false,
          });
          this.$bus.$emit('spinner-stop');
        } else {
          await this.getHistoric(false);
        }
      } else {
        if (this.status === 'paid') {
          this.filters.status.paid = true;
          this.filters.status.pending = false;
          this.filters.status.overdue = false;
          this.filters.status.canceled = false;
          this.filters.status.unauthorized = false;
        } else if (this.status === 'overdue') {
          this.filters.status.paid = false;
          this.filters.status.pending = false;
          this.filters.status.overdue = true;
          this.filters.status.canceled = false;
          this.filters.status.unauthorized = false;
        } else if (this.status === 'canceled') {
          this.filters.status.paid = false;
          this.filters.status.pending = false;
          this.filters.status.overdue = false;
          this.filters.status.canceled = true;
          this.filters.status.unauthorized = false;
        } else if (this.status === 'pending') {
          this.filters.status.paid = false;
          this.filters.status.pending = true;
          this.filters.status.overdue = false;
          this.filters.status.canceled = false;
          this.filters.status.unauthorized = false;
        } else if (this.status === 'unauthorized') {
          this.filters.status.paid = false;
          this.filters.status.pending = false;
          this.filters.status.overdue = false;
          this.filters.status.canceled = false;
          this.filters.status.unauthorized = true;
        } else if (this.status === 'created') {
          this.filters.status.paid = true;
          this.filters.status.pending = true;
          this.filters.status.overdue = true;
          this.filters.status.canceled = true;
          this.filters.status.unauthorized = true;
        } else {
          this.status = 'miscelaneous';
        }
      }
    },
  },
  computed: {
    aliasMethods() {
      var msg = '';
      if (this.filters.methods.length === 0) {
        msg = 'tudo';
        return msg;
      }
      if (this.filters.methods.includes('bankslip')) {
        msg += 'boletos ';
      }
      if (this.filters.methods.includes('bankslipInstallments')) {
        msg += 'parcelas de carnê ';
      }
      if (this.filters.methods.includes('debit')) {
        msg += 'transações no débito ';
      }
      if (this.filters.methods.includes('credit')) {
        msg += 'transações no crédito ';
      }
      if (
        this.filters.methods.includes('debit') &&
        this.filters.methods.includes('credit')
      ) {
        msg = 'transações no crédito e débito ';
      }
      if (this.filters.methods.length > 2) {
        msg = 'três selecionados';
      }
      if (this.filters.methods.length === 4) {
        msg = 'tudo';
      }
      return msg;
    },
    count() {
      return this.filters.methods.length;
    },
    datePickerColor() {
      return window.localStorage.getItem('tertiaryColor') || '#FF7606';
    },
  },
  watch: {
    'filters.methods': async function(newVal) {
      await this.getHistoric(false);
    },
    'filters.bankSlipId': async function(newVal) {
      if (!this.$v.filters.bankSlipId.$invalid) {
        const methods = this.filters.methods.filter(
          (method) => method !== 'credit' && method !== 'debit'
        );

        if (methods.length === 0) {
          this.filters.methods = ['bankslip', 'bankslipInstallments'];
        } else {
          this.filters.methods = methods;
        }
      }
    },
    'filters.customerTaxDocument': async function(newVal) {
      if (!this.$v.filters.customerTaxDocument.$invalid) {
        const methods = this.filters.methods.filter(
          (method) => method !== 'credit' && method !== 'debit'
        );

        if (methods.length === 0) {
          this.filters.methods = ['bankslip', 'bankslipInstallments'];
        } else {
          this.filters.methods = methods;
        }
      }
    },
    'filters.nsu': async function(newVal) {
      if (!this.$v.filters.nsu.$invalid) {
        const methods = this.filters.methods.filter(
          (method) => method !== 'bankslip' && method !== 'bankslipInstallments'
        );

        if (methods.length === 0) {
          this.filters.methods = ['credit', 'debit'];
        } else {
          this.filters.methods = methods;
        }
      }
    },
    'filters.description': async function(newVal) {
      if (newVal.length > 3) {
        const methods = this.filters.methods.filter(
          (method) => method !== 'credit' && method !== 'debit'
        );

        if (methods.length === 0) {
          this.filters.methods = ['bankslip', 'bankslipInstallments'];
        } else {
          this.filters.methods = methods;
        }
      }
    },
    'filters.cardBrands': async function(newVal) {
      const methods = this.filters.methods.filter(
        (method) => method !== 'bankslip' && method !== 'bankslipInstallments'
      );

      if (methods.length === 0) {
        this.filters.methods = ['credit', 'debit'];
      } else {
        this.filters.methods = methods;
      }
    },
    'filters.sellingChannels': async function(newVal) {
      const methods = this.filters.methods.filter(
        (method) => method !== 'bankslip' && method !== 'bankslipInstallments'
      );

      if (methods.length === 0) {
        this.filters.methods = ['credit', 'debit'];
      } else {
        this.filters.methods = methods;
      }
    },
    'filters.bracket': async function(newVal) {
      const methods = this.filters.methods.filter(
        (method) => method !== 'bankslip' && method !== 'bankslipInstallments'
      );

      if (methods.length === 0) {
        this.filters.methods = ['credit', 'debit'];
      } else {
        this.filters.methods = methods;
      }
    },
    'filters.paymentId': async function(newVal) {
      if (!this.$v.filters.paymentId.$invalid) {
        await this.getHistoric(false);
      }
    },
    'selectedDate.start': async function(newVal) {
      this.filters.interval.startDate = moment(newVal).format('YYYY-MM-DD');
    },
    'selectedDate.end': async function(newVal) {
      this.filters.interval.endDate = moment(newVal).format('YYYY-MM-DD');
      await this.getHistoric(false);
    },
    'filters.customerName': async function(newVal) {
      if (newVal.length > 3) {
        const methods = this.filters.methods.filter(
          (method) => method !== 'credit' && method !== 'debit'
        );

        if (methods.length === 0) {
          this.filters.methods = ['bankslip', 'bankslipInstallments'];
        } else {
          this.filters.methods = methods;
        }
      }
    },
    'filters.offset.index': async function(newVal) {
      await this.getHistoric(true);
    },
    'filters.sellingKey': async function(newVal) {
      if (newVal.length > 1) {
        await this.getHistoric(false);
      }
    },
    'filters.status.paid': async function(newVal) {
      await this.setStatus(true);
    },
    'filters.status.pending': async function(newVal) {
      await this.setStatus(true);
    },
    'filters.status.overdue': async function(newVal) {
      await this.setStatus(true);
    },
    'filters.status.canceled': async function(newVal) {
      await this.setStatus(true);
    },
    'filters.status.unauthorized': async function(newVal) {
      await this.setStatus(true);
    },
    status: async function() {
      await this.setStatus(false);
    },
  },
  validations: {
    filters: {
      customerTaxDocument: { cpfCnpj },
      nsu: { onlyNumbers },
      bankSlipId: { onlyNumbers },
      paymentId: { onlyLettersNumbers },
    },
  },
};
</script>
