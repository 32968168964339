<template>
  <div
    class="mb-5"
    v-if="data.records !== undefined && data.records.length > 0"
  >
    <div class="row">
      <div class="col-12 h4 mt-4 mb-3">
        <span class="font-weight-light">{{ data.date | moment('ddd') }}, </span>
        <span class="font-weight-bold">{{
          data.date | moment('DD/MM/YYYY')
        }}</span>
      </div>
      <div class="col-12 col-w-full">
        <div
          class="card card-action py-4 mb-3"
          v-for="(record, key, index) in data.records"
          :key="index"
          :class="[
            record.status === 'paid'
              ? 'card-status-success'
              : record.status === 'canceled'
              ? 'card-status-canceled'
              : record.status === 'pending'
              ? 'card-status-processing'
              : record.status === 'expired'
              ? 'card-status-canceled'
              : record.status === 'unauthorized'
              ? 'card-status-notauthorized'
              : record.status === 'disputed'
              ? 'card-status-canceled'
              : record.status === 'overdue'
              ? 'card-status-delayed'
              : '',
          ]"
        >
          <div class="row m-0">
            <router-link
              class="col col-xl-3 order-1 d-flex"
              :to="{
                name: 'payment-details',
                params: { paymentId: record.id },
              }"
            >
              <div class="my-lg-auto text-truncate">
                <div class="d-flex">
                  <span
                    :class="[
                      record.cardBrand !== 'visa' ? '' : 'visa',
                      record.cardBrand !== 'mastercard' ? '' : 'mastercard',
                      record.cardBrand !== 'amex' ? '' : 'amex',
                      record.cardBrand !== 'dinersclub' ? '' : 'dinersclub',
                      record.cardBrand !== 'elo' ? '' : 'elo',
                      record.cardBrand !== 'hipercard' ? '' : 'hipercard',
                      record.cardBrand !== 'banesecard' ? '' : 'banesecard',
                      record.method === 'bankSlip'
                        ? 'boleto'
                        : record.method === 'bankSlipInstallment'
                        ? 'carne'
                        : '',
                    ]"
                    class="
                      payment-method payment-method-lg
                      my-auto
                      ml-0 ml-lg-3
                      mr-2
                    "
                  >
                  </span>
                  <div class="text-secondary text-sm my-auto">
                    <span class="align-middle">{{ record.cardFinal }}</span>
                    <span class="ml-1 align-middle">{{
                      record.method === 'credit'
                        ? 'crédito'
                        : record.method === 'bankSlip'
                        ? 'boleto'
                        : record.method === 'bankSlipInstallment'
                        ? `parcela ${record.installment}/${record.installments}`
                        : 'débito'
                    }}</span>
                    <span
                      class="
                        badge badge-light-gray
                        text-secondary
                        badge-pill
                        ml-2
                      "
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="
                        record.status === 'pending'
                          ? 'Essa transação foi pré autorizada e pode ter o valor alterado em sua finalização.'
                          : 'Essa transação foi pré autorizada'
                      "
                      v-if="record.dueCaptureDate !== null"
                    >
                      PRÉ
                    </span>
                  </div>
                </div>
                <div
                  class="text-truncate text-sm ml-0 ml-lg-3 mb-1 text-secondary"
                >
                  <span>{{ record.note }}</span>
                </div>
              </div>
            </router-link>
            <router-link
              class="col col-lg-auto order-4 order-lg-2 d-flex text-secondary"
              :to="{
                name: 'payment-details',
                params: { paymentId: record.id },
              }"
            >
              <div class="my-auto">
                <div class="d-flex mb-1" v-if="record.payerName !== null">
                  <span class="d-flex">
                    <i class="icon-user m-auto"></i>
                  </span>
                  <div class="ml-2 small-paragraph">
                    <span class="text-sm">{{ record.payerName }}</span
                    ><br />
                    <span class="text-xs">{{ record.payerTaxDocument }}</span>
                  </div>
                </div>
                <div class="text-sm">
                  <i
                    v-if="record.isPinPad !== null && record.isPinPad"
                    class="icon-screen-smartphone"
                  ></i>
                  <i
                    v-if="record.isPinPad !== null && !record.isPinPad"
                    class="icon-screen-desktop"
                  ></i>
                  <span>{{ record.createdAt | moment('HH:mm:ss') }}</span>
                  <span> • #{{ record.id }}</span>
                  <span v-if="record.nsu !== null">
                    • NSU {{ record.nsu }}</span
                  >
                  <span v-if="record.number !== null">
                    • Nº {{ record.number }}</span
                  >
                </div>
                <div class="d-flex mb-1" v-if="record.observation !== null">
                  <span class="d-flex">
                    <i class="icon-note m-auto"></i>
                  </span>
                  <div class="ml-2 small-paragraph">
                    <span class="text-sm">{{ record.observation }}</span
                    >
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              class="
                col-12 col-lg
                order-3
                d-flex
                text-center text-sm
                mb-3 mb-lg-0
              "
              :to="{
                name: 'payment-details',
                params: { paymentId: record.id },
              }"
            >
              <span
                v-if="record.status === 'disputed'"
                class="ml-auto my-auto text-danger"
                >Contestada em
                {{ record.disputedAt | moment('DD/MM/YYYY') }}</span
              >
              <span
                v-if="record.inDispute === true"
                class="ml-auto my-auto text-danger"
                >Disputa em {{ record.disputedOn | moment('DD/MM/YYYY') }}</span
              >
              <span
                v-if="record.status === 'paid'"
                class="ml-auto my-auto text-success"
                >Pago em
                {{
                  record.capturedAt !== null
                    ? record.capturedAt
                    : record.completedAt | moment('DD/MM/YYYY')
                }}</span
              >
              <span
                v-if="record.status === 'pending'"
                class="ml-auto my-auto text-delayed"
                >Expira em
                {{
                  record.dueCaptureDate !== null
                    ? record.dueCaptureDate
                    : record.nextDueDate | moment('DD/MM/YYYY')
                }}</span
              >
              <span
                v-if="record.status === 'canceled'"
                class="ml-auto my-auto text-canceled"
                >{{ record.canceledAt !== null ? 'Cancelada' : 'Expirou' }} em
                {{
                  record.canceledAt !== null
                    ? record.canceledAt
                    : record.dueCaptureDate | moment('DD/MM/YYYY')
                }}</span
              >
            </router-link>
            <router-link
              class="
                col col-lg-3
                order-2 order-lg-4
                text-right text-secondary
                mt-1
                text-nowrap
              "
              :to="{
                name: 'payment-details',
                params: { paymentId: record.id },
              }"
            >
              <span class="h3">{{
                record.method === 'bankSlipInstallment'
                  ? record.amount
                  : record.status === 'paid'
                  ? record.amountPaid
                  : record.amount
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
              }}</span
              ><br />
              <span
                class="text-xs"
                v-if="
                  record.installments > 1 &&
                  record.method !== 'bankSlipInstallment'
                "
                >{{ record.installments }} X
                {{
                  record.installmentValueAuthorized
                    | currency('R$ ', 2, {
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}</span
              >
            </router-link>
            <div class="col-auto order-5 pl-0 d-flex">
              <div class="dropleft m-auto">
                <button
                  class="btn btn-link"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="icon-options-vertical align-middle"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <router-link
                    :to="{
                      name: 'payment-details',
                      params: { paymentId: record.id },
                    }"
                    class="dropdown-item"
                    >Detalhes da Transação</router-link
                  >
                  <span
                    @click="
                      sendSingleToEmail(
                        record.bankSlipId,
                        record.payerEmail !== null ? record.payerEmail : ''
                      )
                    "
                    v-if="
                      record.method === 'bankSlip' &&
                      record.status === 'pending'
                    "
                    class="dropdown-item cursor-pointer"
                    >Enviar boleto por e-mail</span
                  >
                  <li v-if="record.installments > 1">
                    <span
                      @click="
                        sendToEmail(
                          record.id,
                          record.payerEmail !== null ? record.payerEmail : ''
                        )
                      "
                      v-if="
                        record.method === 'bankSlipInstallment' &&
                        record.status === 'pending'
                      "
                      class="dropdown-item cursor-pointer"
                      >Enviar carnê por e-mail</span
                    >
                  </li>
                  <div
                    class="dropdown-divider"
                    v-if="
                      record.cancelable &&
                      record.status !== 'canceled' &&
                      paymentsPermission
                    "
                  ></div>
                  <li
                    v-if="
                      record.cancelable &&
                      record.status !== 'canceled' &&
                      paymentsPermission
                    "
                  >
                    <a
                      href="javascript:;"
                      class="dropdown-item"
                      @click.prevent="cancelPayment(record.id)"
                    >
                      Cancelar
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import { PermissionList } from '@/models/Permission';
import swal from 'sweetalert2';
import Alert from '@/models/Alert';
export default {
  name: 'AppSalesList',
  // eslint-disable-next-line vue/require-prop-types
  props: ['data'],
  data() {
    return {
      permissionList: new PermissionList(),
      customer: {
        email: '',
      },
    };
  },
  methods: {
    async sendSingleToEmail(number, payerEmail) {
      swal({
        title: 'Digite o e-mail',
        input: 'text',
        inputValue: payerEmail,
        inputPlaceholder: '',
        inputAttributes: {
          minlength: 8,
          autocapitalize: 'off',
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (email) => {
          var regex = new RegExp(
            '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
          );
          if (!email || email.length === 0) {
            swal.showValidationError('E-mail obrigatório!');
          } else if (!regex.test(email)) {
            swal.showValidationError('E-mail inválido!');
          } else {
            this.customer.email = email;
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          this.$bus.$emit('spinner-run');
          const api = new PaymentsApi();

          await api
            .singleBankSlipSendToEmail(number, this.customer)
            .then((result) => {
              if (result.error) {
                this.$bus.$emit('spinner-stop');
                var alert = new Alert();
                alert.title = 'Ops';
                alert.error = 'Você digitou um e-mail inválido.';
                alert.confirmButtonText = 'Sair';
                this.$bus.$emit('alert-error', alert);
              } else {
                this.$bus.$emit('spinner-stop');
                this.isDisabled = true;
                this.$alert.info('Boleto enviado', '');
              }
            })
            .catch((data) => {
              this.$bus.$emit('spinner-stop');
              var alert = new Alert();
              alert.title = 'Ops';
              alert.error =
                'Servidores com preguiça, tente novamente mais tarde.';
              alert.confirmButtonText = 'Ok';
              this.$bus.$emit('alert-error', alert);
            });
        }
      });
    },
    async sendToEmail(paymentId, payerEmail) {
      swal({
        title: 'Digite o e-mail',
        input: 'text',
        inputValue: payerEmail,
        inputPlaceholder: '',
        inputAttributes: {
          minlength: 8,
          autocapitalize: 'off',
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (email) => {
          var regex = new RegExp(
            '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
          );
          if (!email || email.length === 0) {
            swal.showValidationError('E-mail obrigatório!');
          } else if (!regex.test(email)) {
            swal.showValidationError('E-mail inválido!');
          } else {
            this.customer.email = email;
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          this.$bus.$emit('spinner-run');
          const api = new PaymentsApi();
          await api
            .bankSlipSendToEmail(paymentId, this.customer)
            .then((result) => {
              if (result.error) {
                this.$bus.$emit('spinner-stop');
                var alert = new Alert();
                alert.title = 'Ops';
                alert.error = 'Você digitou um e-mail inválido.';
                alert.confirmButtonText = 'Sair';
                this.$bus.$emit('alert-error', alert);
              } else {
                this.$bus.$emit('spinner-stop');
                this.isDisabled = true;
                this.$alert.info('Carnê enviado', '');
              }
            })
            .catch((data) => {
              this.$bus.$emit('spinner-stop');
              var alert = new Alert();
              alert.title = 'Ops';
              alert.error =
                'Servidores com preguiça, tente novamente mais tarde.';
              alert.confirmButtonText = 'Ok';
              this.$bus.$emit('alert-error', alert);
            });
        }
      });
    },
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    async cancelPayment(id) {
      this.$alert.question(
        'Cancelamento de pagamento',
        'Deseja realmente cancelar todo o pagamento?'
      );
      this.$alert.onConfirm = async () => {
        const api = new PaymentsApi();
        this.$bus.$emit('spinner-run');
        await api.cancelPayment(id);
        this.$emit('captureCancelEvent');
        this.$bus.$emit('spinner-stop');
      };
    },
    paymentsPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments'
      );
    },
    paymentsPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    /* eslint-disable no-undef */
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
  computed: {
    // paymentsPermissionIndex () {
    //   return this.permissionList.permissions.findIndex(x => x.name === 'payments')
    // },
    // isAccountHolder () {
    //   if (!this.whoAmI) return ''
    //   return this.whoAmI.accessedByHolder
    // },
    // paymentsPermission () {
    //   if ((this.permissionList.permissions[this.paymentsPermissionIndex].grant === 'create') || this.isAccountHolder) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
};
</script>
