var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.records !== undefined && _vm.data.records.length > 0)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 h4 mt-4 mb-3"},[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm._f("moment")(_vm.data.date,'ddd'))+", ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("moment")(_vm.data.date,'DD/MM/YYYY')))])]),_c('div',{staticClass:"col-12 col-w-full"},_vm._l((_vm.data.records),function(record,key,index){return _c('div',{key:index,staticClass:"card card-action py-4 mb-3",class:[
          record.status === 'paid'
            ? 'card-status-success'
            : record.status === 'canceled'
            ? 'card-status-canceled'
            : record.status === 'pending'
            ? 'card-status-processing'
            : record.status === 'expired'
            ? 'card-status-canceled'
            : record.status === 'unauthorized'
            ? 'card-status-notauthorized'
            : record.status === 'disputed'
            ? 'card-status-canceled'
            : record.status === 'overdue'
            ? 'card-status-delayed'
            : '' ]},[_c('div',{staticClass:"row m-0"},[_c('router-link',{staticClass:"col col-xl-3 order-1 d-flex",attrs:{"to":{
              name: 'payment-details',
              params: { paymentId: record.id },
            }}},[_c('div',{staticClass:"my-lg-auto text-truncate"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"\n                    payment-method payment-method-lg\n                    my-auto\n                    ml-0 ml-lg-3\n                    mr-2\n                  ",class:[
                    record.cardBrand !== 'visa' ? '' : 'visa',
                    record.cardBrand !== 'mastercard' ? '' : 'mastercard',
                    record.cardBrand !== 'amex' ? '' : 'amex',
                    record.cardBrand !== 'dinersclub' ? '' : 'dinersclub',
                    record.cardBrand !== 'elo' ? '' : 'elo',
                    record.cardBrand !== 'hipercard' ? '' : 'hipercard',
                    record.cardBrand !== 'banesecard' ? '' : 'banesecard',
                    record.method === 'bankSlip'
                      ? 'boleto'
                      : record.method === 'bankSlipInstallment'
                      ? 'carne'
                      : '' ]}),_c('div',{staticClass:"text-secondary text-sm my-auto"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(record.cardFinal))]),_c('span',{staticClass:"ml-1 align-middle"},[_vm._v(_vm._s(record.method === 'credit' ? 'crédito' : record.method === 'bankSlip' ? 'boleto' : record.method === 'bankSlipInstallment' ? ("parcela " + (record.installment) + "/" + (record.installments)) : 'débito'))]),(record.dueCaptureDate !== null)?_c('span',{staticClass:"\n                      badge badge-light-gray\n                      text-secondary\n                      badge-pill\n                      ml-2\n                    ",attrs:{"data-toggle":"tooltip","data-placement":"top","title":record.status === 'pending'
                        ? 'Essa transação foi pré autorizada e pode ter o valor alterado em sua finalização.'
                        : 'Essa transação foi pré autorizada'}},[_vm._v(" PRÉ ")]):_vm._e()])]),_c('div',{staticClass:"text-truncate text-sm ml-0 ml-lg-3 mb-1 text-secondary"},[_c('span',[_vm._v(_vm._s(record.note))])])])]),_c('router-link',{staticClass:"col col-lg-auto order-4 order-lg-2 d-flex text-secondary",attrs:{"to":{
              name: 'payment-details',
              params: { paymentId: record.id },
            }}},[_c('div',{staticClass:"my-auto"},[(record.payerName !== null)?_c('div',{staticClass:"d-flex mb-1"},[_c('span',{staticClass:"d-flex"},[_c('i',{staticClass:"icon-user m-auto"})]),_c('div',{staticClass:"ml-2 small-paragraph"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(record.payerName))]),_c('br'),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(record.payerTaxDocument))])])]):_vm._e(),_c('div',{staticClass:"text-sm"},[(record.isPinPad !== null && record.isPinPad)?_c('i',{staticClass:"icon-screen-smartphone"}):_vm._e(),(record.isPinPad !== null && !record.isPinPad)?_c('i',{staticClass:"icon-screen-desktop"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("moment")(record.createdAt,'HH:mm:ss')))]),_c('span',[_vm._v(" • #"+_vm._s(record.id))]),(record.nsu !== null)?_c('span',[_vm._v(" • NSU "+_vm._s(record.nsu))]):_vm._e(),(record.number !== null)?_c('span',[_vm._v(" • Nº "+_vm._s(record.number))]):_vm._e()]),(record.observation !== null)?_c('div',{staticClass:"d-flex mb-1"},[_c('span',{staticClass:"d-flex"},[_c('i',{staticClass:"icon-note m-auto"})]),_c('div',{staticClass:"ml-2 small-paragraph"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(record.observation))])])]):_vm._e()])]),_c('router-link',{staticClass:"\n              col-12 col-lg\n              order-3\n              d-flex\n              text-center text-sm\n              mb-3 mb-lg-0\n            ",attrs:{"to":{
              name: 'payment-details',
              params: { paymentId: record.id },
            }}},[(record.status === 'disputed')?_c('span',{staticClass:"ml-auto my-auto text-danger"},[_vm._v("Contestada em "+_vm._s(_vm._f("moment")(record.disputedAt,'DD/MM/YYYY')))]):_vm._e(),(record.inDispute === true)?_c('span',{staticClass:"ml-auto my-auto text-danger"},[_vm._v("Disputa em "+_vm._s(_vm._f("moment")(record.disputedOn,'DD/MM/YYYY')))]):_vm._e(),(record.status === 'paid')?_c('span',{staticClass:"ml-auto my-auto text-success"},[_vm._v("Pago em "+_vm._s(_vm._f("moment")(record.capturedAt !== null ? record.capturedAt : record.completedAt,'DD/MM/YYYY')))]):_vm._e(),(record.status === 'pending')?_c('span',{staticClass:"ml-auto my-auto text-delayed"},[_vm._v("Expira em "+_vm._s(_vm._f("moment")(record.dueCaptureDate !== null ? record.dueCaptureDate : record.nextDueDate,'DD/MM/YYYY')))]):_vm._e(),(record.status === 'canceled')?_c('span',{staticClass:"ml-auto my-auto text-canceled"},[_vm._v(_vm._s(record.canceledAt !== null ? 'Cancelada' : 'Expirou')+" em "+_vm._s(_vm._f("moment")(record.canceledAt !== null ? record.canceledAt : record.dueCaptureDate,'DD/MM/YYYY')))]):_vm._e()]),_c('router-link',{staticClass:"\n              col col-lg-3\n              order-2 order-lg-4\n              text-right text-secondary\n              mt-1\n              text-nowrap\n            ",attrs:{"to":{
              name: 'payment-details',
              params: { paymentId: record.id },
            }}},[_c('span',{staticClass:"h3"},[_vm._v(_vm._s(_vm._f("currency")(record.method === 'bankSlipInstallment' ? record.amount : record.status === 'paid' ? record.amountPaid : record.amount,'R$ ', 2, { decimalSeparator: ',', thousandsSeparator: '.', })))]),_c('br'),(
                record.installments > 1 &&
                record.method !== 'bankSlipInstallment'
              )?_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(record.installments)+" X "+_vm._s(_vm._f("currency")(record.installmentValueAuthorized,'R$ ', 2, { decimalSeparator: ',', thousandsSeparator: '.', })))]):_vm._e()]),_c('div',{staticClass:"col-auto order-5 pl-0 d-flex"},[_c('div',{staticClass:"dropleft m-auto"},[_vm._m(0,true),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                    name: 'payment-details',
                    params: { paymentId: record.id },
                  }}},[_vm._v("Detalhes da Transação")]),(
                    record.method === 'bankSlip' &&
                    record.status === 'pending'
                  )?_c('span',{staticClass:"dropdown-item cursor-pointer",on:{"click":function($event){return _vm.sendSingleToEmail(
                      record.bankSlipId,
                      record.payerEmail !== null ? record.payerEmail : ''
                    )}}},[_vm._v("Enviar boleto por e-mail")]):_vm._e(),(record.installments > 1)?_c('li',[(
                      record.method === 'bankSlipInstallment' &&
                      record.status === 'pending'
                    )?_c('span',{staticClass:"dropdown-item cursor-pointer",on:{"click":function($event){return _vm.sendToEmail(
                        record.id,
                        record.payerEmail !== null ? record.payerEmail : ''
                      )}}},[_vm._v("Enviar carnê por e-mail")]):_vm._e()]):_vm._e(),(
                    record.cancelable &&
                    record.status !== 'canceled' &&
                    _vm.paymentsPermission
                  )?_c('div',{staticClass:"dropdown-divider"}):_vm._e(),(
                    record.cancelable &&
                    record.status !== 'canceled' &&
                    _vm.paymentsPermission
                  )?_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.cancelPayment(record.id)}}},[_vm._v(" Cancelar ")])]):_vm._e()],1)])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-link",attrs:{"id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"icon-options-vertical align-middle"})])}]

export { render, staticRenderFns }