<template>
  <div class="row">
    <div
      class="
        col-12 col-w-full
        flex-nowrap
        overflow-auto
        d-flex
        py-4
        px-4 px-xl-2
      "
    >
      <div class="totalizer-card" id="success-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" v-model="totalizers.paid" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{ data.paidQuantity }}</span>
              <span class="totalizer-title mb-3">PAGAS / APROVADAS</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                data.paidAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="processing-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" v-model="totalizers.pending" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{ data.pendingQuantity }}</span>
              <span class="totalizer-title mb-3">em aberto</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                data.pendingAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="delayed-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" v-model="totalizers.overdue" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{ data.overdueQuantity }}</span>
              <span class="totalizer-title mb-3">vencidas</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                data.overdueAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="canceled-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" v-model="totalizers.canceled" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{
                data.expiredQuantity + data.canceledQuantity
              }}</span>
              <span class="totalizer-title mb-3">expiradas / canceladas</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                (data.expiredAmount + data.canceledAmount)
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
            <div
              class="text-center mt-2 transactions-alert"
              :disabled="!totalizers.canceled"
            >
              <span class="badge badge-pill badge-danger">{{
                data.disputedQuantity
              }}</span
              >&nbsp;
              <span class="text-sm text-ghost">contestadas</span>
            </div>
          </div>
        </label>
      </div>
      <div class="totalizer-card" id="notauthorized-tlz">
        <label class="totalizer-checkbox">
          <input type="checkbox" v-model="totalizers.unauthorized" />
          <div class="card-checkbox">
            <div class="card">
              <span class="sales-quantitative">{{
                data.unauthorizedQuantity
              }}</span>
              <span class="totalizer-title mb-3">Não autorizadas</span>
              <div class="status mb-3"></div>
              <span class="money-totalizer mb-1">{{
                data.unauthorizedAmount
                  | currency('R$ ', 2, {
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Totalizers from '@/models/Totalizers';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['data'],
  name: 'AppSalesTotalizers',
  data() {
    return {
      totalizers: new Totalizers(),
    };
  },
  async mounted() {
    if (localStorage.hasOwnProperty('totalizers')) {
      this.totalizers = await JSON.parse(localStorage.getItem('totalizers'));

      await this.sendTotalizersControlToParent();
    }
  },
  methods: {
    async sendTotalizersControlToParent() {
      localStorage.setItem('totalizers', JSON.stringify(this.totalizers));

      this.$emit('captureTotalizers', this.totalizers);
    },
  },
  watch: {
    'totalizers.paid': async function (newVal) {
      await this.sendTotalizersControlToParent();
    },
    'totalizers.canceled': async function (newVal) {
      await this.sendTotalizersControlToParent();
    },
    'totalizers.pending': async function (newVal) {
      await this.sendTotalizersControlToParent();
    },
    'totalizers.overdue': async function (newVal) {
      await this.sendTotalizersControlToParent();
    },
    'totalizers.unauthorized': async function (newVal) {
      await this.sendTotalizersControlToParent();
    },
    'totalizers.disputed': async function (newVal) {
      await this.sendTotalizersControlToParent();
    },
  },
};
</script>
