<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="30"
  >
    <div class="container mt-4">
      <div>
        <button
          class="
            btn btn-white
            text-orange
            btn-pill
            mx-auto
            btn-drawer-open
            text-uppercase
            btn-float
          "
        >
          <i class="icon-filter"></i>
          Filtros
        </button>
        <div class="bottom-drawer">
          <div class="bottom-drawer-header">
            <h3 class="font-weight-bold mb-0 my-auto">Filtros</h3>
            <button class="btn btn-lg btn-link btn-drawer-close ml-auto p-0">
              <i class="icon-close"></i>
            </button>
          </div>
          <app-sales-form
            @captureHistoric="historicAfterEvent"
            ref="salesForm"
          ></app-sales-form>
        </div>
        <div class="row justify-content-center font-weight-light">
          <div>
            <span
              v-if="historic !== null && historic.transactions.length > 0"
              >{{ historic.count }}</span
            >
            <span v-if="historic !== null && historic.transactions.length > 0"
              >transações encontradas</span
            >
          </div>
          <div class="ml-3 d-none d-lg-inline-block">
            <WhiteLabel>
              <a
                href="#"
                class="text-uppercase text-sm p-0"
                @click="cleanupFilters"
                >limpar filtros</a
              >
            </WhiteLabel>
          </div>
        </div>
        <app-sales-totalizers
          v-if="historic !== null"
          :data="historic.totals"
          @captureTotalizers="historicAfterEventFilter"
          ref="salesTotalizers"
        ></app-sales-totalizers>
      </div>
    </div>
    <div class="container" v-if="historic !== null">
      <app-sales-list
        v-for="transaction in historic.transactions"
        :data="transaction"
        :key="transaction.id"
        @captureCancelEvent="getHistoricAfterEvent"
      />
    </div>
    <div v-if="historic === null">
      <div class="text-center">
        <h4>Nenhuma transação encontrada.</h4>
      </div>
    </div>
  </div>
</template>

<script>
import AppSalesForm from '@/components/financial/sales/sales-filters/SalesForm';
import AppSalesTotalizers from '@/components/financial/sales/sales-filters/SalesTotalizers';
import AppSalesList from '@/components/financial/sales/SalesList';
import SalesHistoricFilters from '@/models/SalesHistoricFilters';
import Totalizers from '@/models/Totalizers';
import { cpfCnpj } from '@/validations/cpfCnpj';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppSalesHistory',
  data() {
    return {
      historic: null,
      historicFull: null,
      totalizers: null,
      tempTotalizers: null,
      perPage: 30,
      isInterval: true,
      isValidInterval: true,
      filtersOn: false,
    };
  },
  components: {
    AppSalesForm,
    AppSalesTotalizers,
    AppSalesList,
    WhiteLabel,
  },
  async mounted() {
    $('.btn-drawer-close').on('click', async () => this.hide());
    $('.btn-drawer-open').on('click', async () => this.show());
  },
  methods: {
    hide() {
      $('.bottom-drawer').removeClass('show');
    },
    show() {
      $('.bottom-drawer').addClass('show');
      this.filtersOn = true;
    },
    async historicAfterEvent({ historic, paginate }) {
      if (paginate && historic !== null) {
        if (
          this.historic.transactions[this.historic.transactions.length - 1]
            .date === historic.transactions[0].date
        ) {
          this.historic.transactions[
            this.historic.transactions.length - 1
          ].records = this.historic.transactions[
            this.historic.transactions.length - 1
          ].records.concat(historic.transactions[0].records);
          historic.transactions.splice(0, 1);
        }
        this.historic.transactions = this.historic.transactions.concat(
          historic.transactions
        );
      } else {
        this.historic = historic;
      }
      this.historicFull = await JSON.parse(JSON.stringify(this.historic));

      await this.$refs.salesTotalizers.sendTotalizersControlToParent();
    },
    async historicAfterEventFilter(totalizers) {
      this.totalizers = totalizers;
      await this.totalizersFilters();
    },
    async getHistoricAfterEvent() {
      await this.$refs.salesForm.getHistoric(false);
    },
    async cleanupFilters() {
      this.$refs.salesForm.filters = new SalesHistoricFilters();
      this.$refs.salesForm.selectedDate.start = new Date().addDays(-7);
      this.$refs.salesForm.selectedDate.end = new Date();
      this.$refs.salesTotalizers.totalizers = new Totalizers();
    },
    async totalizersFilters() {
      this.historic = JSON.parse(JSON.stringify(this.historicFull));

      this.$refs.salesForm.filters.status = this.totalizers;
    },

    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.$refs.salesForm.filters.offset.index = inicio;
    },

    loadMore: async function () {
      if (this.historic !== null) {
        var pagination =
          this.$refs.salesForm.filters.offset.index + this.perPage;
        if (pagination < this.historic.count) {
          this.$refs.salesForm.filters.offset.index = pagination;
        }
      }
    },
  },
  watch: {
    totalizers: async function () {
      await this.totalizersFilters();
    },
  },
};
</script>
